<template>
  <the-header></the-header>
  <div class="p-3 grid grid-cols-4 gap-4">
    <the-card v-for="(tab, index) in links" :key="index" :tab="tab"></the-card>
  </div>
</template>

<script setup>
import { reactive } from '@vue/reactivity';
import TheCard from './components/TheCard.vue';
import TheHeader from './components/TheHeader.vue';
import Links from '../public/link.json';

const links = reactive(Links);
console.log(links.value)

</script>

<style>

</style>
